:root{
  --edit-color: #6ec6ff;
  --edit-color-light: hsl(197, 81%, 85%);
  --lightgray: #e7e7e7;
}

.canvas {
  display: flex
}

* {
  box-sizing: border-box;
}
.app {
  display: flex;
  width: 100%;
}

.inputmodule:focus{
  outline: none
}
.inputmodule {
  border: none;
  width: 100%;
  padding: 0;
  background: none
}

:focus {
  outline: none
}

input::placeholder,
.placeholder{
  color: #dadada;
  font-family: Helvetica, sans-serif;
  font-weight: normal;
  margin: 0
}

textarea {
  font-family: inherit;
  resize: none;
  overflow: hidden
}


.column{
  position: relative;
  width: 100%;
  display:flex;
  overflow-x: hidden;
  overflow-y:hidden;
  z-index: 0;
}

.columns div{
  border: 1px solid red
}

.column button {
  background-color: var(--edit-color);
  border: none;
  border-top-left-radius: 5px;
  color: white;
  font-weight: bold;
  font-size: .7rem;
  padding: 3px 3px;
  cursor: pointer;
  transition: all 75ms ease-in-out
}

.column button:hover {
  background-color: var(--edit-color-light);
  color: #00aff5
}

.drawer {
  width: 30%;
  height: 100%;
}

.module {
  width: 100%
}

.move {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  left: -2rem;
  top: 0%;
  z-index: 10;
  background-color: #9698AD;
  color: white;
  cursor: pointer;
  padding: .2rem 0rem 0rem;
  font-size: 15px;
  border-radius: 5px;
}

.remove {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  right: -2rem;
  top: 0%;
  z-index: 99;
  background-color: #545663;
  color: white;
  cursor: pointer;
  padding: .2rem .2rem;
  font-size: 15px;
  border-radius: 5px;
}

.resize {
  height: 20px;
  width: 16px;
  background-color: blue;
  position: absolute;
  right: -8px;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
  cursor: col-resize;
}

.selected {
  outline: 2px solid var(--edit-color);
  border-radius: 2px;
}

.hovermodule {
  box-shadow: 0 0 0 2px var(--lightgray); /* All the borders by using the spread properties */
  cursor: pointer!important;
  border-radius: 2px;
  transition: all 150ms ease-in-out
}

.autocomplete-label {
  display: flex;
  align-items:flex-end;
  justify-content: space-between;
  
}

.autocomplete-label .label-end {
  font-size: .6rem;
  color: var(--edit-color);
  position: absolute;
  right: .5rem;
  bottom: 0
}

.swiper-button-prev {
  margin-left: -15px!important;
  position: absolute!important;
  z-index: 9999!important;
}
