/* Generated by Fontie <http://fontie.pixelsvsbytes.com> */

@font-face {
    font-family:'Arial';
    src: url('Arial.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-02DC;
}

@font-face {
    font-family:'Calibri';
    src: url('Calibri.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-02DC;
}

@font-face {
    font-family:'Gill Sans';
    src: url('Gill-Sans.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-02DC;
}

@font-face {
    font-family:'Courier New';
    src: url('Courier-New.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-02DC;
}

@font-face {
    font-family:'Georgia';
    src: url('Georgia.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-02DC;
}

@font-face {
    font-family:'Trebuchet MS';
    src: url('Trebuchet-MS.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-02DC;
}