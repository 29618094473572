.editbar {
    height: 100vh;
    width: 300px;
    position: fixed;
    background-color: var(--edit-color-light);
    z-index: 99;
    right: 0;
    top: 0;
    padding: 0 2rem
}

.editbar button {
    background-color: #fdfdfd;
    border: 1px solid darkgray;
    display: flex;
    align-items: center;
    border-radius: 2px;
    margin-right: 1px;
    cursor: pointer;
    transition: all 70ms ease-in-out
}

.editbar button:hover{
    background-color: lightgray;
}

.editbar .active {
    box-shadow: inset 0px 0px 1px 1px #00000070;
    background-color: lightgray;
}